import React, { FC, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import cn from 'classnames';
import useSearchField from 'hooks/useSearchField';
import { useUserTheme } from 'domains/user/model/selectors';
import { FiSearch, FiX } from 'react-icons/fi';
import AccessUserRight from 'components/AccessUserRight';
import Button from 'components/UI/Buttons/buttons';
import ElementsNone from 'components/ElementsNone';
import TableFilter from 'components/UI/TableFilter';
import { RouteComponentProps } from 'react-router';
import { useDispatchApp } from 'redux/rootSelectors';
import { useTranslation } from 'react-i18next';
import PreloaderList from 'components/Preloaders/List';
import NavigationHeader from 'components/NavigationHeader';
import css from './styles.module.scss';
import Item from './Item';
import { useCounteragentsList } from '../model/selectors';
import { getCounteragentsList, getOrdList } from '../model/actions';
import { ReactComponent as FilterSvg } from '../../contracts/ui/icons/filters.svg';

type Props = RouteComponentProps;

const Counteragents: FC<Props> = ({ ...params }) => {
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = useState<
    'ACTIVE' | 'DELETED' | 'ON_APPROVAL' | 'ALL'
  >('ACTIVE');
  const searchRef = useRef<HTMLInputElement>(null);
  const searchWrapperRef = useRef<HTMLDivElement>(null);
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    useSearchField(searchRef, searchWrapperRef);
  const { isThemeExtended, isThemeFull } = useUserTheme();

  const dispatch = useDispatchApp();

  const { data: counteragentsList, isLoading } = useCounteragentsList();
  const couneragentView = counteragentsList?.filter(
    (counteragent) =>
      counteragent.name.toLowerCase().includes(search.toLowerCase()) ||
      counteragent.inn?.toLowerCase().includes(search.toLowerCase()),
  );

  useEffect(() => {
    dispatch(getCounteragentsList({}));
    dispatch(getOrdList());
  }, []);

  return (
    <div className={css.contractors}>
      <Helmet>
        <title>{t(`ord.сounterparties_title`)}</title>
      </Helmet>
      <NavigationHeader>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ACTIVE',
          })}
          onClick={() => {
            setCurrentTab('ACTIVE');
            dispatch(
              getCounteragentsList({
                filter: {
                  status: ['ACTIVE'],
                },
              }),
            );
          }}
        >
          {t(`agencies_page.agencies_active`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ON_APPROVAL',
          })}
          onClick={() => {
            setCurrentTab('ON_APPROVAL');
            dispatch(
              getCounteragentsList({
                filter: {
                  status: ['ON_APPROVAL'],
                },
              }),
            );
          }}
        >
          {t(`ord.contracts.in_moderation`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'DELETED',
          })}
          onClick={() => {
            setCurrentTab('DELETED');
            dispatch(
              getCounteragentsList({
                filter: {
                  status: ['DELETED'],
                },
              }),
            );
          }}
          disabled={false}
        >
          {t(`campaigns_page.campaigns_black_header_main.del`)}
        </button>
        <button
          type="button"
          className={cn('tabs__item', {
            isActive: currentTab === 'ALL',
          })}
          onClick={() => {
            setCurrentTab('ALL');
            dispatch(
              getCounteragentsList({
                filter: {
                  status: ['ACTIVE', 'ON_APPROVAL', 'DELETED'],
                },
              }),
            );
          }}
          disabled={false}
        >
          {t(`targetings.all`)}
        </button>
      </NavigationHeader>
      <div
        className={cn(css.header, {
          isToggleSearch: toggleSearch,
        })}
      >
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          <div className={css.status}>
            <h1 className={css.title}>{t(`ord.сounterparties_title`)}</h1>
            <div className={css.actions}>
              <div
                ref={searchWrapperRef}
                className={cn(css.search, {
                  isActive: toggleSearch,
                })}
              >
                <div className={css.icon} onClick={onOpenSearch}>
                  <FiSearch size={24} />
                </div>
                <div className={css.input}>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t(`ord.сounterparties_search`)}
                    value={search}
                    onChange={onChangeSearch}
                    ref={searchRef}
                  />
                </div>

                <div className={css.close} onClick={onCloseSearch}>
                  <FiX size={24} />
                </div>
              </div>
              <AccessUserRight>
                {({ isDisable }) => (
                  <Button
                    title={t(`ord.counterparte_create`)}
                    buttonAttributes={{
                      disabled: isDisable,
                      style: {
                        height: 40,
                      },
                      onClick: () =>
                        params.history.push({
                          pathname: `counteragent/create`,
                        }),
                    }}
                  />
                )}
              </AccessUserRight>
            </div>
          </div>
        </div>
      </div>
      {couneragentView?.length === 0 ? (
        <ElementsNone type="counteragents" search={!!search} status="" />
      ) : (
        <div className={css.content}>
          <div
            className={cn('container resized', {
              extend: isThemeExtended,
              full: isThemeFull,
            })}
          >
            <div className={css.table}>
              <div className={css.table_header}>
                <div className={css.filter_header}>
                  <FilterSvg />
                </div>
                <TableFilter
                  data=""
                  title={t(`ord.name_of_legal`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.name_for_export`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.synonyms_name`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.inn`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
                <TableFilter
                  data=""
                  title={t(`ord.title`)}
                  handleSort={() => null}
                  sort={null}
                  activeKey={null}
                />
              </div>
              {isLoading && <PreloaderList type="counteragents" />}
              {!isLoading && (
                <div className={css.table_body}>
                  {couneragentView?.map((counteragent) => (
                    <Item
                      counteragentData={counteragent}
                      key={counteragent.uuid}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Counteragents;
