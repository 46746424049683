import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatchApp } from 'redux/rootSelectors';
import { RouteComponentProps } from 'react-router-dom';
import {
  usePartnerBalanceInfo,
  usePartnerStatisticFieldList,
} from 'domains/campaigns/model/selectors';
import { useSelector } from 'react-redux';
import { fetchStatisticFieldList } from 'domains/campaigns/model/actions';
import { TFieldStatistics } from 'domains/campaign/types';
import { resetStatisticFieldList } from 'domains/campaigns/reducer';
import { useCreativeComponentState } from './useCreativeComponentState';
import {
  cleanCreativeTargeting,
  creativeChangeSetStatus,
  creativeCloneRequest,
  featchAllCreativeTypesAction,
  fetchCreativesList,
  fetchCreativesTranslation,
  resetCreativeList,
  setListSort,
} from '../../reduser';
import { KeysSettings, useStateValue } from '../../../../utils/context';
import { useCreativeListFilter, useHighlights } from '../../hooks';
import {
  Creative,
  CreativeItemListWithStatistics,
  RCreativeClone,
} from '../../types';
import { ErrorResponse, TID, TPropsSelector } from '../../../../types/general';
import {
  changeGroupCreativesStatuses,
  clearGroupCreativesTargeting,
  loadCreativeList,
  searchCreativeList,
} from '../../model/actions';
import { useUserTheme } from '../../../user/model/selectors';
import {
  selectorListSort,
  selectorTranslationFilter,
  useCreativeActiveTag,
} from '../../model/selectors';

export type PHandlerCloneCreative = {
  xxhash: string;
  campaign_xxhash: string;
};
export type PHandlerRequestDelete = {
  xxhash: string;
  campaign_xxhash: string;
  title: string;
};

export type PHandlerRequestCleanAllTargets = {
  xxhash: string;
  title: string;
};

export type PHandlerChangeStatus = {
  xxhash: string;
  activeStatus: boolean;
  setStatus: (p: boolean) => void;
};

type TFUseCreativeList = (
  p: TPropsSelector<CreativeItemListWithStatistics[]> & {
    search: string;
    currentFilter: string;
    currentTranslationXxhashArr: string[];
  },
) => {
  sumStatistics: ReturnType<typeof useCreativeComponentState>['sumStatistics'];
  lastId: string;
  colClass: Record<string, string>;
  listFilter: ReturnType<typeof useCreativeListFilter>['listFilter'];
  campaign: ReturnType<typeof useCreativeComponentState>['campaign'];
  toggle: string;
  setToggle: (v: string) => void;
  openCleanModal: string;
  setOpenCleanModal: (v: string) => void;
  cloneResponse: RCreativeClone | ErrorResponse | null;
  cloneResult: 'SUCCESS' | 'ERROR' | null;
  openDeleteModal: string;
  setOpenDeleteModal: (v: string) => void;
  handlerCloneCreative: (v: PHandlerCloneCreative) => void;
  openCopyModal: string;
  setOpenCopyModal: (v: string) => void;
  handlerRequestDelete: (v: PHandlerRequestDelete) => void;
  handlerRequestCleanAllTargets: (v: PHandlerRequestCleanAllTargets) => void;
  handlerChangeStatus: (v: PHandlerChangeStatus) => void;
  copyCreative: string;
  isThemeExtended: ReturnType<typeof useUserTheme>['isThemeExtended'];
  isThemeFull: ReturnType<typeof useUserTheme>['isThemeFull'];
  isTop: boolean;
  onFilterToggleDropdown: (v: boolean) => void;
  keysFields: TFieldStatistics[];
  setCloneResult: (v: ReturnType<TFUseCreativeList>['cloneResult']) => void;
  setCloneResponse: React.Dispatch<
    React.SetStateAction<RCreativeClone | ErrorResponse | null>
  >;
  isShowEmpty: boolean;
  match: RouteComponentProps<TID>['match'];
  isModalAllStatus: boolean;
  setIsModalAllStatus: (v: boolean) => void;
  creativeCheckedList: string[];
  handlerSetCreativeInCheckedList: (value: CreativeItemListWithStatistics) => void;
  allCreativeChecked: boolean;
  allPartialCreativeChecked: boolean;
  handlerAllCheckedCreatives: (value: boolean) => void;
  handlerClickStatusCreativesEdit: (status: AllStatusesAction) => void;
  generateTextIsModalText: Record<'text' | 'button', string>;
  currentCheckedItems: React.MutableRefObject<CreativeItemListWithStatistics[]>;
  handlerCreativesUpdatedStatus: () => void;
  isCopyCreativeModal: boolean;
  setIsCopyCreativeModal: (value: boolean) => void;
  setCreativeCheckedList: (value: string[]) => void;
  isLoadingSearch: boolean;
  isMassEditModal: boolean;
  setIsMassEditModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type AllStatusesAction = Creative['status'] | 'CLEAR_TARGET' | 'DUPLICATE';

export const useCreativeList: TFUseCreativeList = ({
  list,
  serverList,
  isLoading,
  LTU,
  search,
  currentFilter,
  currentTranslationXxhashArr,
}) => {
  const { t } = useTranslation();

  const BUTTON_TEXT: Record<AllStatusesAction, string> = {
    STOPPED: t('stop'),
    LAUNCHED: t('start'),
    DELETED: t('delete_btn'),
    ARCHIVE: t(`ord.contracts.archived`),
    CLEAR_TARGET: '',
    DUPLICATE: t('duplicate_btn'),
  };

  const AlERT_TEXT: Record<AllStatusesAction, string> = {
    STOPPED: t('stopped'),
    LAUNCHED: t('launched'),
    DELETED: t('deleted'),
    ARCHIVE: t(`ord.contracts.archived`),
    DUPLICATE: 'дублированы',
    CLEAR_TARGET: 'очищены',
  };

  const { isThemeExtended, isThemeFull } = useUserTheme();
  const [isTop, setIsTop] = useState<boolean>(false);
  const { data: balanceData } = usePartnerBalanceInfo();

  const [isCopyCreativeModal, setIsCopyCreativeModal] = useState(false);
  const [isMassEditModal, setIsMassEditModal] = useState<boolean>(false);

  const onFilterToggleDropdown: ReturnType<TFUseCreativeList>['onFilterToggleDropdown'] =
    (value) => {
      setIsTop(value);
    };

  const translationData = useSelector(selectorTranslationFilter);
  const listSort = useSelector(selectorListSort);

  const isDefaultTranslationFilter = currentFilter === 'isAll';

  const { sumStatistics, location, match, currency, campaign } =
    useCreativeComponentState();

  const dispatchRedux = useDispatchApp();

  const { listFilter } = useCreativeListFilter();

  const { state } = useStateValue();
  const { period, type } = state.settings[KeysSettings.CAMPAIGN];
  const [isShowEmpty, setShowEmpty] = useState<boolean>(false);

  const { data: statisticsFields, LTU: statFieldsLTU } =
    usePartnerStatisticFieldList();

  const [keysFields, setKeysFields] = useState<TFieldStatistics[]>([]);

  const createKeysFields = useMemo<TFieldStatistics[]>(() => {
    let data: TFieldStatistics[] = [];
    if (statisticsFields) {
      statisticsFields.forEach(({ key, checked, title }) => {
        if (checked) {
          if (key === 'cpc' || key === 'cpm') {
            data = [
              ...data,
              {
                key,
                title: `${title}`,
                isLengthTitle: `${title}`.length,
              },
            ];
          } else if (key === 'show') {
            data = [
              ...data,
              {
                key,
                title: t('creative.Impressions'),
                isLengthTitle: t('creative.Impressions').length,
              },
            ];
          } else if (key === 'click') {
            data = [
              ...data,
              {
                key,
                title: t('creative.Clicks'),
                isLengthTitle: t('creative.Clicks').length,
              },
            ];
          } else if (key === 'spent') {
            data = [
              ...data,
              {
                key,
                title: `${t('creative.Amount_spent')}`,
                isLengthTitle: `${t('creative.Amount_spent')}`.length,
              },
            ];
          } else {
            data = [...data, { key, title, isLengthTitle: title.length }];
          }
        }
      });
    }
    return data;
  }, [statFieldsLTU]);

  useEffect(() => {
    if (createKeysFields.length) {
      setKeysFields(createKeysFields);
    }
  }, [createKeysFields]);

  const colClass = useMemo<ReturnType<TFUseCreativeList>['colClass']>(() => {
    let data: Record<string, string> = {};
    if (sumStatistics && keysFields) {
      Object.keys(sumStatistics).forEach((key) => {
        if (
          `${Math.trunc(sumStatistics[key])}`.length <
          +(
            keysFields.some((field) => field.key === key) &&
            keysFields.filter((field) => field.key === key)[0].isLengthTitle
          )
        ) {
          data = {
            ...data,
            [key]: `size-${keysFields.filter((field) => field.key === key)[0].isLengthTitle
              }`,
          };
        } else {
          data = {
            ...data,
            [key]: `size-${String(Math.trunc(sumStatistics[key])).length}`,
          };
        }
      });
    }
    if (
      keysFields &&
      sumStatistics &&
      Object.keys(sumStatistics).length === 0
    ) {
      keysFields.forEach(({ key: keyField, isLengthTitle }) => {
        data = { ...data, [keyField]: `size-${isLengthTitle}` };
      });
    }
    return data;
  }, [sumStatistics, keysFields]);

  const { id } = match.params;
  const lastId = useHighlights();
  const { getValueFromSettingForKey } = useUserTheme();

  const scrollHandler = ({ target }: Event) => {
    const scroll = target as HTMLDocument;
    if (
      !search &&
      serverList &&
      scroll.documentElement.scrollHeight -
      (scroll.documentElement.scrollTop + window.innerHeight) <
      70
    ) {
      dispatchRedux(loadCreativeList({}));
    }
  };

  useEffect(() => {
    dispatchRedux(featchAllCreativeTypesAction());
  }, []);

  useEffect(() => {
    if (campaign) {
      /* Обязательно очищать стейт при размонтировании списка */
      dispatchRedux(
        fetchStatisticFieldList({
          xxhash: campaign.xxhash,
          types: ['All', 'Creative'],
        }),
      );
    }
  }, [campaign]);

  useEffect(
    () => () => {
      dispatchRedux(resetStatisticFieldList());
    },
    [],
  );

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  useEffect(() => {
    dispatchRedux(searchCreativeList({ search, period }));
  }, [search]);

  useEffect(() => {
    if (search) {
      dispatchRedux(
        searchCreativeList({
          search,
          period,
          callbacks: { setIsLoadingSearch },
        }),
      );
    }
  }, [translationData]);

  useEffect(() => {
    if (search) {
      dispatchRedux(
        searchCreativeList({
          search,
          period,
          callbacks: { setIsLoadingSearch },
          blockTotalRequest: true,
        }),
      );
    }
  }, [listSort]);

  useEffect(() => {
    dispatchRedux(
      fetchCreativesList({
        campaign_xxhash: id,
        filters: { status: listFilter },
        period,
        typePeriod: type,
      }),
    );

    return function () {
      document.removeEventListener('scroll', scrollHandler);
      dispatchRedux(resetCreativeList());
      dispatchRedux(setListSort({ key: null, sort: null }));
    };
  }, [location]);

  useEffect(() => {
    if (
      getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
      !getValueFromSettingForKey('ShowBroadcastStatus') &&
      balanceData
    ) {
      dispatchRedux(fetchCreativesTranslation({ xxhash: id }));
    }
  }, [location, balanceData]);

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler);
    return function () {
      document.removeEventListener('scroll', scrollHandler);
    };
  }, [list]);

  const currentCheckedItems = useRef<CreativeItemListWithStatistics[]>([])

  /** состояние блока при наведении */
  const [toggle, setToggle] = useState<string>('');
  /** состояние модального окнаща */
  const [openCleanModal, setOpenCleanModal] = useState<string>('');

  /** состояние результата ответа сервера */
  const [cloneResult, setCloneResult] = useState<'SUCCESS' | 'ERROR' | null>(
    null,
  );
  /** ответ сервера */
  const [cloneResponse, setCloneResponse] = useState<
    RCreativeClone | ErrorResponse | null
  >(null);

  /** состояние модального окна копирования */
  const [openCopyModal, setOpenCopyModal] = useState<string>('');

  /** состояние модального окна удаления */
  const [openDeleteModal, setOpenDeleteModal] = useState<string>('');

  /** креатив для дублирования */
  const [copyCreative, setCopyCreative] = useState<string>('');

  /** функция дублирования креатива */
  const handlerCloneCreative: ReturnType<TFUseCreativeList>['handlerCloneCreative'] =
    ({ xxhash, campaign_xxhash }) => {
      setCopyCreative(xxhash);
      dispatchRedux(
        creativeCloneRequest({
          creative_xxhash_list: [xxhash],
          campaign_xxhash_list: [campaign_xxhash],
          translation:
            getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
            !getValueFromSettingForKey('ShowBroadcastStatus'),
          setResponse: setCloneResponse,
          setResult: setCloneResult,
          paramRerender: {
            campaign_xxhash,
            period,
          },
        }),
      );
    };

  /** функция удаления креатива */
  const handlerRequestDelete: ReturnType<TFUseCreativeList>['handlerRequestDelete'] =
    ({ campaign_xxhash, xxhash, title }) => {
      dispatchRedux(
        creativeChangeSetStatus({
          xxhash,
          status: 'DELETED',
          visual: {
            setter: setOpenDeleteModal as (p: string | boolean) => void,
            value: openDeleteModal,
            paramRender: {
              period,
              campaign_xxhash,
            },
            alertText: {
              before: t('creative.del_crea.t1'),
              title: `"${title}"`,
              after: t('creative.del_crea.t2'),
            },
          },
        }),
      );
    };

  /** функция очистки креативов */
  const handlerRequestCleanAllTargets: ReturnType<TFUseCreativeList>['handlerRequestCleanAllTargets'] =
    ({ xxhash, title }) => {
      dispatchRedux(
        cleanCreativeTargeting({
          isUpdateList: true,
          xxhash,
          visual: {
            setter: setOpenCleanModal,
            value: openCleanModal,
            alertText: {
              before: `${t('common.targetings.clean_all_targeting_creative')} `,
              title: ` "${title}" `,
              after: ` ${t('common.targetings.clean_all_targeting_success')}`,
            },
          },
        }),
      );
    };

  /** функция изменения статуса креатива */
  const handlerChangeStatus: ReturnType<TFUseCreativeList>['handlerChangeStatus'] =
    ({ xxhash, activeStatus, setStatus }) => {
      dispatchRedux(
        creativeChangeSetStatus({
          xxhash,
          status: activeStatus ? 'STOPPED' : 'LAUNCHED',
          visual: {
            setter: setStatus as (p: string | boolean) => void,
            value: activeStatus,
            translation:
              getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
              !getValueFromSettingForKey('ShowBroadcastStatus'),
          },
        }),
      );
    };

  const [isModalAllStatus, setIsModalAllStatus] = useState(false);

  const [creativeCheckedList, setCreativeCheckedList] = useState<string[]>([]);

  const handlerSetCreativeInCheckedList: ReturnType<TFUseCreativeList>['handlerSetCreativeInCheckedList'] =
    useCallback(
      (creoData) => {
        if (creativeCheckedList.includes(creoData.xxhash)) {
          currentCheckedItems.current = currentCheckedItems.current.filter(item => item.xxhash !== creoData.xxhash)
          setCreativeCheckedList((prev) =>
            prev.filter((item) => item !== creoData.xxhash),
          );
        } else {
          currentCheckedItems.current.push(creoData)
          setCreativeCheckedList((prev) => [...prev, creoData.xxhash]);
        }
      },
      [creativeCheckedList],
    );

  const getCreativeChecked = useMemo<{
    all: boolean;
    partially: boolean;
  }>(() => {
    let partially = false;
    let all = false;
    if (serverList) {
      let listHash: string[];
      if (search || !isDefaultTranslationFilter) {
        listHash = list.map(({ xxhash }) => xxhash);
      } else {
        listHash = serverList.map(({ xxhash }) => xxhash);
      }
      const listHashFilter = listHash.filter((value) =>
        creativeCheckedList.some((item) => item === value),
      );

      if (listHashFilter.length > 0) {
        if (listHashFilter.length === listHash.length) {
          all = true
        } else {
          partially = true
        }
      }
    }
    return {
      all,
      partially,
    };
  }, [
    creativeCheckedList,
    list,
    serverList,
    listFilter,
    search,
    currentFilter,
  ]);

  const mappedList = (listArr: CreativeItemListWithStatistics[]) =>
    listArr.map(({ xxhash }) => xxhash);

  const handlerAllCheckedCreatives: ReturnType<TFUseCreativeList>['handlerAllCheckedCreatives'] =
    (isSomeChecked) => {
      if (!serverList) return;
      let hashList: string[] = [];
      let checkedItemsData: CreativeItemListWithStatistics[] = []

      if (search) {
        hashList = [...creativeCheckedList, ...mappedList(list)];
        checkedItemsData = [...currentCheckedItems.current, ...list]
      } else if (!isDefaultTranslationFilter) {
        hashList = [
          ...mappedList(
            serverList.filter((item) =>
              currentTranslationXxhashArr.includes(item.xxhash),
            ),
          ),
          ...creativeCheckedList,
        ];
        checkedItemsData = [...serverList.filter((item) =>
          currentTranslationXxhashArr.includes(item.xxhash),
        ), ...currentCheckedItems.current]
      } else {
        hashList = mappedList(serverList);
        checkedItemsData = [...serverList]
      }

      setCreativeCheckedList(() => {
        if (isSomeChecked) {
          if (search || !isDefaultTranslationFilter) {
            const currentList = search
              ? list
              : serverList.filter((item) =>
                currentTranslationXxhashArr.includes(item.xxhash),
              );
            currentCheckedItems.current = currentCheckedItems.current.filter(
              (creo) => !currentList.some((item) => item.xxhash === creo.xxhash)
            )
            return creativeCheckedList.filter(
              (creo) => !currentList.some((item) => item.xxhash === creo),
            );
          }
          currentCheckedItems.current = []
          return [];
        }
        currentCheckedItems.current = checkedItemsData
        return hashList;
      });
    };

  const allCheckedStatus = useRef<AllStatusesAction | ''>('');

  const handlerClickStatusCreativesEdit: ReturnType<TFUseCreativeList>['handlerClickStatusCreativesEdit'] =
    useCallback((status) => {
      setIsModalAllStatus(true);
      allCheckedStatus.current = status;
    }, []);

  const generateTextIsModalText: ReturnType<TFUseCreativeList>['generateTextIsModalText'] =
    useMemo(() => {
      let resultText = `${t(`creative.mass_action.modal_test_part1`)} ${t(
        `creative.mass_action.modal_test_part3`,
      )} ${BUTTON_TEXT[allCheckedStatus.current]?.toLowerCase()} ${t(
        `creative.mass_action.modal_test_part2`,
      )}`;
      let button = BUTTON_TEXT[allCheckedStatus.current];

      switch (allCheckedStatus.current) {
        case 'CLEAR_TARGET':
          resultText = `
            ${t(`creative.mass_action.modal_test_part1`)}
            ${BUTTON_TEXT[allCheckedStatus.current]?.toLowerCase()}
            ${t(`creative.mass_action.modal_test_part4`)}
            `;
          button = t('creative.cleartarg');
          break;
        default:
          break;
      }
      return {
        text: resultText,
        button,
      };
    }, [allCheckedStatus.current]);

  const activeTags = useCreativeActiveTag();

  const handlerCreativesUpdatedStatus = useCallback(() => {
    if (allCheckedStatus.current && campaign) {
      const isModeGroup =
        creativeCheckedList.length !== serverList?.length ||
        listFilter ||
        activeTags.length;
      if (
        ['STOPPED', 'LAUNCHED', 'DELETED'].includes(allCheckedStatus.current)
      ) {
        dispatchRedux(
          changeGroupCreativesStatuses({
            status: allCheckedStatus.current as Creative['status'],
            creative_xxhash_list: creativeCheckedList,
            campaign_xxhash: campaign.xxhash,
            callback: () => {
              setIsModalAllStatus(false);
              setCreativeCheckedList([]);
            },
            listFilter: { status: listFilter, tags: activeTags },
            mode: isModeGroup ? 'GROUP' : 'ALL',
            translation:
              getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
              !getValueFromSettingForKey('ShowBroadcastStatus'),
            alertText: `${t(`creative.mass_action.successfully_text_part1`)} ${AlERT_TEXT[allCheckedStatus.current]
              } ${t(`creative.mass_action.successfully_text_part2`)}`,
          }),
        );
      }
      if (allCheckedStatus.current === 'DUPLICATE') {
        if (creativeCheckedList.length === 1) {
          setCopyCreative(creativeCheckedList[0]);
        } else {
          setCopyCreative('');
        }
        dispatchRedux(
          creativeCloneRequest({
            creative_xxhash_list: creativeCheckedList,
            campaign_xxhash_list: [campaign.xxhash],
            translation:
              getValueFromSettingForKey('ShowBroadcastStatus') !== null &&
              !getValueFromSettingForKey('ShowBroadcastStatus'),
            setResponse: setCloneResponse,
            setResult: setCloneResult,
            alertText: t(`creative.mass_action.successfully_text_dublicate`),
            callbackFinally: () => {
              setIsModalAllStatus(false);
              setCreativeCheckedList([]);
            },
            paramRerender: {
              campaign_xxhash: campaign.xxhash,
              period,
            },
          }),
        );
      }
      if (allCheckedStatus.current === 'CLEAR_TARGET') {
        dispatchRedux(
          clearGroupCreativesTargeting({
            xxhash_list: creativeCheckedList,
            campaign_xxhash: campaign.xxhash,
            callback: () => {
              setIsModalAllStatus(false);
              setCreativeCheckedList([]);
            },
            listFilter: { status: listFilter, tags: activeTags },
            mode: isModeGroup ? 'GROUP' : 'ALL',
            alertText: t(`creative.mass_action.successfully_text_clear`),
          }),
        );
      }
    }
  }, [
    allCheckedStatus.current,
    serverList,
    creativeCheckedList,
    list,
    campaign,
  ]);

  useEffect(() => {
    setCreativeCheckedList([]);
    if (!isLoading && serverList && serverList.length === 0) {
      setShowEmpty(true);
    } else {
      setShowEmpty(false);
    }
  }, [LTU]);

  return {
    sumStatistics,
    currency,
    lastId,
    match,
    colClass,
    listFilter,
    campaign,
    toggle,
    setToggle,
    openCleanModal,
    setOpenCleanModal,
    cloneResponse,
    cloneResult,
    openDeleteModal,
    setOpenDeleteModal,
    handlerCloneCreative,
    openCopyModal,
    setOpenCopyModal,
    handlerRequestDelete,
    handlerRequestCleanAllTargets,
    handlerChangeStatus,
    copyCreative,
    setCopyCreative,
    isThemeExtended,
    isThemeFull,
    isTop,
    onFilterToggleDropdown,
    keysFields,
    setCloneResult,
    setCloneResponse,
    isShowEmpty,
    isModalAllStatus,
    setIsModalAllStatus,
    creativeCheckedList,
    handlerSetCreativeInCheckedList,
    allCreativeChecked: getCreativeChecked.all,
    allPartialCreativeChecked: getCreativeChecked.partially,
    handlerAllCheckedCreatives,
    handlerClickStatusCreativesEdit,
    generateTextIsModalText,
    handlerCreativesUpdatedStatus,
    isCopyCreativeModal,
    setIsCopyCreativeModal,
    setCreativeCheckedList,
    isLoadingSearch,
    isMassEditModal,
    currentCheckedItems,
    setIsMassEditModal,
  };
};
