import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FetchedData, genFetchedData } from '../../../redux/fetchedData';
import { ContractItem, TCampaignOrd } from './types';

export type contractState = {
  contractData: FetchedData<ContractItem>;
  emptyCampaignData: FetchedData<TCampaignOrd>;
};

export const initialState: contractState = {
  contractData: genFetchedData<ContractItem>(null),
  emptyCampaignData: genFetchedData<TCampaignOrd>(null),
};

const contractSlice = createSlice({
  name: 'Contract',
  initialState,
  reducers: {
    setContractData(
      state,
      action: PayloadAction<contractState['contractData']>,
    ) {
      state.contractData = action.payload;
    },
    setEmptyCampaign(
      state,
      action: PayloadAction<contractState['emptyCampaignData']>,
    ) {
      state.emptyCampaignData = action.payload;
    },
  },
});

export const { setContractData, setEmptyCampaign } = contractSlice.actions;
export const contractReducer = contractSlice.reducer;