import React, { FC, useRef, useState } from 'react';
import { animated } from 'react-spring';
import cn from 'classnames';
import Tooltip from 'components/UI/Tooltip';
import { FiPlusSquare } from 'react-icons/fi';
import Button from 'components/UI/Buttons/buttons';
import { ReactSVG } from 'react-svg';
import { IHistoryFilter } from 'domains/historyChanges/types';
import { useHistoryFiltersClear } from 'domains/historyChanges/model/hooks/useHistoryFiltersClear';
import { SelectField } from 'types/general';
import { FormikProps } from 'formik';
import { TCampaignsFilters } from '../../domains/campaigns/types';
import css from './styles.module.scss';
import { filterOptionsCompare } from '../../domains/campaigns/model/shared';
import { selectDefault } from '../../utils/selectDefault';
import { useFilter } from './hook/useFilter';
import i18next from '../../i18n';
import campFilterSvg from '../../assets/campFilter.svg';
import CampaignMode from './mods/CampaignMode';
import OffersMode from './mods/OffersMode';
import ContractsMode from './mods/ContractsMode';

type FilterProps = {
  classname?: string;
  data?: TCampaignsFilters;
  formik?: FormikProps<{ partner_xxhash_list: SelectField[] }>;
  handleApplyFilter?: (v: TCampaignsFilters) => void;
  applyHistoryFilters?: () => void;
  applyContructorFilters?: () => void;
  mode?: string;
  setHistoryFilters?: React.Dispatch<React.SetStateAction<IHistoryFilter>>;
  filtersLength?: number;
  setLastHistoryFilters?: React.Dispatch<React.SetStateAction<IHistoryFilter>>;
  clearOfferFilters?: () => void;
  clearContractFilter?: () => void;
  setContractsFilters?: React.Dispatch<
    React.SetStateAction<{
      executor: string;
      customer: string;
      status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL' | 'ALL';
    }>
  >;
  contractsFilters?: {
    executor: string;
    customer: string;
    status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL' | 'ALL';
  };
};

const Filter: FC<FilterProps> = ({
  classname,
  data,
  handleApplyFilter,
  applyHistoryFilters,
  applyContructorFilters,
  mode,
  formik,
  filtersLength,
  contractsFilters,
  setHistoryFilters,
  setLastHistoryFilters,
  setContractsFilters,
  clearOfferFilters,
  clearContractFilter,
}) => {
  const [offersFilterCount, setOffersFilterCount] = useState(0);

  const {
    filters,
    handleClearFilter,
    handleRemoveItemFilter,
    handleAddFilter,
    open,
    applyFilter,
    changeItem,
    isValidItem,
    togglePanel,
    fieldRef,
    showFilter,
    wrapperRef,
  } = useFilter({ data, handleApplyFilter, applyHistoryFilters });

  // ссылки на селект поля фильтров
  const typeSelect = useRef<any>(null);
  const actionSelect = useRef<any>(null);
  const fieldSelect = useRef<any>(null);
  const emailSelect = useRef<HTMLInputElement>(null);
  const searchSelect = useRef<HTMLInputElement>(null);
  // функционал очистки фильтров истории
  const { clearFilters, filtersCount } = useHistoryFiltersClear(
    typeSelect,
    actionSelect,
    fieldSelect,
    emailSelect,
    searchSelect,
    setHistoryFilters,
    setLastHistoryFilters,
    applyHistoryFilters,
    open,
  );

  const filterOptions: SelectField[] = [
    {
      label: i18next.t('filter.date__start'),
      value: 'start',
    },
    {
      label: i18next.t('filter.date__end'),
      value: 'end',
    },
  ];

  const launchApplyFunc = () => {
    if (applyContructorFilters) {
      applyContructorFilters();
      if (filtersLength) {
        setOffersFilterCount(filtersLength);
      }
      togglePanel(false);
    }
    if (mode === 'offers' && formik) {
      formik.handleSubmit();
      togglePanel(false);
      setOffersFilterCount(formik.values.partner_xxhash_list.length);
    }
    applyFilter();
  };

  return (
    <div className={cn(css.filter, classname)} ref={wrapperRef}>
      {!!data?.length && <div className={css.found}>{data.length}</div>}
      {!!(filtersCount || offersFilterCount) && (
        <div className={css.found}>
          {mode === 'offers' || setContractsFilters
            ? offersFilterCount
            : filtersCount}
        </div>
      )}
      <Tooltip
        bottom
        className={css.filterTooltip}
        title={
          <div
            className={cn(css.filterBtn, {
              _isFound:
                (data && data.length !== 0) ||
                !!(filtersCount || offersFilterCount),
            })}
            onClick={() => togglePanel(!open)}
          >
            <ReactSVG src={campFilterSvg} />
          </div>
        }
      >
        {i18next.t('filter.customize')}
      </Tooltip>
      <animated.div style={showFilter} className="panel_wrapper">
        <div
          className={cn(css.panel, {
            _isEmpty: !filters.length && mode === 'campaign',
          })}
        >
          <div
            className={cn(css.settingsBox, {
              _isScroll: filters.length > 4,
              _isHistory: mode === 'history',
            })}
          >
            <div className={cn(css.settings)}>
              {setContractsFilters && contractsFilters && (
                <ContractsMode
                  setContractsFilters={setContractsFilters}
                  contractsFilters={contractsFilters}
                />
              )}
              {mode === 'campaign' && (
                <CampaignMode
                  filters={filters}
                  fieldRef={fieldRef}
                  filterOptions={filterOptions}
                  selectDefault={selectDefault}
                  changeItem={changeItem}
                  filterOptionsCompare={filterOptionsCompare}
                  isValidItem={isValidItem}
                  handleRemoveItemFilter={handleRemoveItemFilter}
                />
              )}
              {mode === 'offers' && formik && <OffersMode formik={formik} />}
            </div>
          </div>
          <div className={css.actions}>
            {handleAddFilter && mode === 'campaign' && (
              <Button
                lightblue
                icon={<FiPlusSquare size={17} />}
                title={i18next.t('add_btn')}
                height={32}
                buttonAttributes={{
                  disabled: filters.length > 3,
                  className: css.add,
                  onClick: handleAddFilter,
                }}
              />
            )}
            {handleClearFilter && mode === 'campaign' && (
              <Button
                lightgrey
                title={i18next.t(`clean_btn`)}
                height={32}
                buttonAttributes={{
                  onClick: handleClearFilter,
                  disabled: !filters.length,
                  style: {
                    color: '#222222',
                  },
                }}
              />
            )}
            {mode === 'history' && (
              <Button
                lightgrey
                title={i18next.t(`clean_btn`)}
                height={32}
                buttonAttributes={{
                  onClick: clearFilters,
                  disabled: !filtersCount,
                  style: {
                    color: '#222222',
                  },
                }}
              />
            )}
            {setContractsFilters && (
              <Button
                lightgrey
                title={i18next.t(`clean_btn`)}
                height={32}
                buttonAttributes={{
                  onClick: () => {
                    if (clearContractFilter) {
                      clearContractFilter();
                    }
                    setContractsFilters((prev) => ({
                      ...prev,
                      customer: '',
                      executor: '',
                    }));
                    setOffersFilterCount(0);
                  },
                  disabled: !filtersLength,
                  style: {
                    color: '#222222',
                  },
                }}
              />
            )}
            {mode === 'offers' && (
              <Button
                lightgrey
                title={i18next.t(`clean_btn`)}
                height={32}
                buttonAttributes={{
                  onClick: () => {
                    if (clearOfferFilters) {
                      clearOfferFilters();
                      setOffersFilterCount(0);
                      formik?.handleSubmit();
                    }
                  },
                  disabled: !formik?.values.partner_xxhash_list.length,
                }}
              />
            )}
            <Button
              title={i18next.t('apply_btn')}
              height={32}
              buttonAttributes={{
                className: css.apply,
                onClick: launchApplyFunc,
              }}
            />
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default Filter;
