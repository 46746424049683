import React, { FC, useMemo } from 'react';
import { useCounteragentsList } from 'domains/counteragents/model/selectors';
import { useTranslation } from 'react-i18next';
import NewSelect from 'components/UI/NewSelect';
import css from '../../styles.module.scss';

interface Props {
  setContractsFilters: React.Dispatch<
    React.SetStateAction<{
      executor: string;
      customer: string;
      status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL' | 'ALL';
    }>
  >;
  contractsFilters: {
    executor: string;
    customer: string;
    status: 'ACTIVE' | 'DELETED' | 'ON_APPROVAL' | 'ALL';
  };
}

const ContractsMode: FC<Props> = ({
  contractsFilters,
  setContractsFilters,
}) => {
  const { t } = useTranslation();
  const { data: counteragentsList } = useCounteragentsList();

  const counteragentOptions = useMemo(
    () =>
      counteragentsList?.map(({ name, uuid }) => ({
        label: name,
        value: uuid,
      })),
    [counteragentsList],
  );

  return (
    <>
      <div className={css.settingsItem}>
        <NewSelect
          selectAttributes={{
            placeholder: t(`ord.customer_select`),
            options: counteragentOptions || [],
            value:
              counteragentOptions?.find(
                (item) => item.value === contractsFilters.customer,
              ) || null,
          }}
          onCustomChange={(val) =>
            setContractsFilters((prev) => ({ ...prev, customer: val }))
          }
          viewStyle="Horizontal"
        />
      </div>
      <div className={css.settingsItem}>
        <NewSelect
          selectAttributes={{
            placeholder: t(`ord.contractor_select`),
            options: counteragentOptions || [],
            value:
              counteragentOptions?.find(
                (item) => item.value === contractsFilters.executor,
              ) || null,
          }}
          onCustomChange={(val) =>
            setContractsFilters((prev) => ({ ...prev, executor: val }))
          }
          viewStyle="Horizontal"
        />
      </div>
    </>
  );
};

export default ContractsMode;
