import { THookSelectorFetchDataInfo, TSelector, useGenFetchDataSelector } from 'redux/rootSelectors';
import { ContractItem, TCampaignOrd } from './types';
import { contractState } from './reducer';

export const selectorContract: TSelector<contractState['contractData']> = ({
  contractReducer,
}) => contractReducer.contractData;

export const selectorEmptyCampaign: TSelector<contractState['emptyCampaignData']> = ({
  contractReducer,
}) => contractReducer.emptyCampaignData;

export const useContractData: THookSelectorFetchDataInfo<ContractItem> = () =>
  useGenFetchDataSelector(selectorContract);

export const useEmptyCampaignData: THookSelectorFetchDataInfo<TCampaignOrd> = () =>
  useGenFetchDataSelector(selectorEmptyCampaign);
