import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { TMakeReqWithRD, makeReqWithRD } from 'redux/makeReqWithRD';
import { toastError, toastSuccess } from 'utils/ToastUtils/toastUtils';
import { FetchedData, genFetchedData } from 'redux/fetchedData';
import { toastSuccessBold } from 'utils/ToastUtils/toastSuccessBold';
import i18next from 'i18next';
import {
  checkErirStatusAPI,
  createContractAPI,
  createEmptyCampaignAPI,
  getContractAPI,
  getEmptyCampaignAPI,
  sendErirAPI,
  updateContractAPI,
} from '../api';
import {
  createContract,
  createEmptyCampaign,
  getContract,
  getEmptyCampaign,
  sendErir,
  updateContract,
} from '../actions';
import { setContractData, setEmptyCampaign } from '../reducer';
import { selectorContract } from '../selectors';
import { ContractItem, getStatusResponse } from '../types';

export function* workerGetContract({
  payload,
}: ReturnType<typeof getContract>): SagaIterator<void> {
  try {
    yield call<TMakeReqWithRD<typeof getContractAPI>>(makeReqWithRD, {
      fetcher: getContractAPI,
      fill: setContractData,
      parameters: payload,
    });
  } catch (e) {
    if (e.code === 90) {
      const newRD = genFetchedData<ContractItem>(null);
      newRD.set('error', {
        isError: true,
        message: 'Not found',
        code: e.code,
      });
      yield put(setContractData(newRD));
    }
    console.error({ e });
  }
}

export function* workerCreateContract({
  payload,
}: ReturnType<typeof createContract>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true);
    }
    const result = yield call(createContractAPI, params);
    if (result) {
      toastSuccessBold(`ord.contact_created`, {
        title: params.objectCuster.number,
      });
      if (callbacks.goBack) {
        callbacks.goBack();
      }
    }
  } catch (e) {
    if (e.code === 40) {
      let newRD = genFetchedData<ContractItem>(null);
      newRD = newRD.set('error', {
        isError: true,
        message: e.data.description,
        code: e.code,
        fields: ['creativeOkveds'],
      });
      yield put(setContractData(newRD));
    }
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false);
    }
  }
}

export function* workerUpdateContract({
  payload,
}: ReturnType<typeof updateContract>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true);
    }
    const result = yield call(updateContractAPI, params);
    if (result) {
      toastSuccess({
        before: `Контракт `,
        title: `${params.objectCuster.number} `,
        after: 'успешно отредактирован',
      });
      if (callbacks.goBack) {
        callbacks.goBack();
      }
    }
  } catch (e) {
    if (e.code === 40) {
      let newRD = genFetchedData<ContractItem>(payload.contractData);
      newRD = newRD.set('error', {
        isError: true,
        message: e.data.description,
        code: e.code,
        fields: ['creativeOkveds'],
      });
      yield put(setContractData(newRD));
    }
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false);
    }
  }
}

export function* workerSendErir({
  payload,
}: ReturnType<typeof sendErir>): SagaIterator<void> {
  try {
    const result = yield call(sendErirAPI, payload);
    if (result) {
      const statusData: getStatusResponse = yield call(
        checkErirStatusAPI,
        payload,
      );
      if (statusData) {
        const contractDataMap: FetchedData<ContractItem> = yield select(
          selectorContract,
        );
        const currentContractData = contractDataMap.get('data');
        if (currentContractData) {
          currentContractData.erirStatus = statusData.erirStatus;
          currentContractData.erirExportedOn = statusData.erirExportedOn;
          currentContractData.isReadyForErir = statusData.isReadyForErir;
          currentContractData.loadingError = statusData.loadingError;
          currentContractData.erirPlannedExportDate =
            statusData.erirPlannedExportDate;
          yield put(
            setContractData(contractDataMap.set('data', currentContractData)),
          );
        }
      }
    }
  } catch (e) {
    console.error({ e });
  }
}

export function* workerCreateEmptyCampaign({
  payload,
}: ReturnType<typeof createEmptyCampaign>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true);
    }
    const result = yield call(createEmptyCampaignAPI, params);
    if (result) {
      yield call<TMakeReqWithRD<typeof getEmptyCampaignAPI>>(makeReqWithRD, {
        fetcher: getEmptyCampaignAPI,
        fill: setEmptyCampaign,
        parameters: payload,
      });
      toastSuccessBold(`ord.contact_created`, {
        title: 'Кампания успешно создана',
      });
    }
  } catch (e) {
    if (e.code === 40) {
      toastError(i18next.t('ord.errors.allready_exists_grid'));
    }
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false);
    }
  }
}

export function* workerGetEmptyCampaign({
  payload,
}: ReturnType<typeof getEmptyCampaign>): SagaIterator<void> {
  const { callbacks, ...params } = payload;
  try {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(true);
    }
    yield call<TMakeReqWithRD<typeof getEmptyCampaignAPI>>(makeReqWithRD, {
      fetcher: getEmptyCampaignAPI,
      fill: setEmptyCampaign,
      parameters: params,
    });
  } catch (e) {
    console.error({ e });
  } finally {
    if (callbacks.setIsLoading) {
      callbacks.setIsLoading(false);
    }
  }
}